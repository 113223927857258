export function Btn_10_150() {
  var element_10_150 = document.getElementsByClassName('10_150')
  var element_10_156 = document.getElementsByClassName('10_156')
  var element_10_161 = document.getElementsByClassName('10_161')
  var element_10_167 = document.getElementsByClassName('10_167')
  var element_10_174 = document.getElementsByClassName('10_174')

  var Btn_10_150 = document.getElementsByClassName('Btn_10_150')
  var Btn_10_156 = document.getElementsByClassName('Btn_10_156')
  var Btn_10_161 = document.getElementsByClassName('Btn_10_161')
  var Btn_10_167 = document.getElementsByClassName('Btn_10_167')
  var Btn_10_174 = document.getElementsByClassName('Btn_10_174')
  var Btn_Todas10 = document.getElementsByClassName('Btn_Todas10')

  //var NomeDaRefracao = document.getElementsByClassName('NomeDaRefracao')
  document
    .getElementById('Background_Espessuras')
    .classList.remove('Afasta_Background')
  document.getElementById('Background_Sala').classList.remove('Afasta_Sala')

  for (var i = 0; i < element_10_150.length; i++) {
    element_10_150[i].classList.remove('Todas10_150')
  }
  for (var i = 0; i < element_10_150.length; i++) {
    element_10_150[i].classList.remove('Todas10_150_Mais')
  }

  for (var i = 0; i < element_10_150.length; i++) {
    for (var i = 0; i < element_10_156.length; i++) {
      element_10_156[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_161.length; i++) {
      element_10_161[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_167.length; i++) {
      element_10_167[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_174.length; i++) {
      element_10_174[i].classList.add('LenteInativa')
    }

    setTimeout(function () {
      for (var i = 0; i < element_10_150.length; i++) {
        element_10_150[i].classList.remove('LenteInativa')
      }
      for (var i = 0; i < element_10_150.length; i++) {
        element_10_150[i].classList.add('LenteAtiva')
      }
    }, 200)
  }

  for (var i = 0; i < Btn_10_150.length; i++) {
    Btn_10_150[i].classList.add('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_156.length; i++) {
    Btn_10_156[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_161.length; i++) {
    Btn_10_161[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_167.length; i++) {
    Btn_10_167[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_174.length; i++) {
    Btn_10_174[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_Todas10.length; i++) {
    Btn_Todas10[i].classList.remove('BtnDefault_Ativo')
  }

  //NomeDaRefracao.html('Com índice de refração 1.50')
}

export function Btn_10_156() {
  var element_10_150 = document.getElementsByClassName('10_150')
  var element_10_156 = document.getElementsByClassName('10_156')
  var element_10_161 = document.getElementsByClassName('10_161')
  var element_10_167 = document.getElementsByClassName('10_167')
  var element_10_174 = document.getElementsByClassName('10_174')

  var Btn_10_150 = document.getElementsByClassName('Btn_10_150')
  var Btn_10_156 = document.getElementsByClassName('Btn_10_156')
  var Btn_10_161 = document.getElementsByClassName('Btn_10_161')
  var Btn_10_167 = document.getElementsByClassName('Btn_10_167')
  var Btn_10_174 = document.getElementsByClassName('Btn_10_174')
  var Btn_Todas10 = document.getElementsByClassName('Btn_Todas10')

  //var NomeDaRefracao = document.getElementsByClassName('NomeDaRefracao')

  document
    .getElementById('Background_Espessuras')
    .classList.remove('Afasta_Background')
  document.getElementById('Background_Sala').classList.remove('Afasta_Sala')

  for (var i = 0; i < element_10_156.length; i++) {
    element_10_156[i].classList.remove('Todas10_156')
  }

  for (var i = 0; i < element_10_156.length; i++) {
    for (var i = 0; i < element_10_150.length; i++) {
      element_10_150[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_161.length; i++) {
      element_10_161[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_167.length; i++) {
      element_10_167[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_174.length; i++) {
      element_10_174[i].classList.add('LenteInativa')
    }

    setTimeout(function () {
      for (var i = 0; i < element_10_156.length; i++) {
        element_10_156[i].classList.remove('LenteInativa')
      }
      for (var i = 0; i < element_10_156.length; i++) {
        element_10_156[i].classList.add('LenteAtiva')
      }
    }, 200)
  }

  for (var i = 0; i < Btn_10_150.length; i++) {
    Btn_10_150[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_150.length; i++) {
    Btn_10_150[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_156.length; i++) {
    Btn_10_156[i].classList.add('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_161.length; i++) {
    Btn_10_161[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_167.length; i++) {
    Btn_10_167[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_174.length; i++) {
    Btn_10_174[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_Todas10.length; i++) {
    Btn_Todas10[i].classList.remove('BtnDefault_Ativo')
  }
  //NomeDaRefracao.html('Com índice de refração 1.56')
}

export function Btn_10_161() {
  var element_10_150 = document.getElementsByClassName('10_150')
  var element_10_156 = document.getElementsByClassName('10_156')
  var element_10_161 = document.getElementsByClassName('10_161')
  var element_10_167 = document.getElementsByClassName('10_167')
  var element_10_174 = document.getElementsByClassName('10_174')

  var Btn_10_150 = document.getElementsByClassName('Btn_10_150')
  var Btn_10_156 = document.getElementsByClassName('Btn_10_156')
  var Btn_10_161 = document.getElementsByClassName('Btn_10_161')
  var Btn_10_167 = document.getElementsByClassName('Btn_10_167')
  var Btn_10_174 = document.getElementsByClassName('Btn_10_174')
  var Btn_Todas10 = document.getElementsByClassName('Btn_Todas10')

  //var NomeDaRefracao = document.getElementsByClassName('NomeDaRefracao')

  document
    .getElementById('Background_Espessuras')
    .classList.remove('Afasta_Background')
  document.getElementById('Background_Sala').classList.remove('Afasta_Sala')

  for (var i = 0; i < element_10_161.length; i++) {
    element_10_161[i].classList.remove('Todas10_161')
  }

  for (var i = 0; i < element_10_161.length; i++) {
    for (var i = 0; i < element_10_150.length; i++) {
      element_10_150[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_156.length; i++) {
      element_10_156[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_167.length; i++) {
      element_10_167[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_174.length; i++) {
      element_10_174[i].classList.add('LenteInativa')
    }

    setTimeout(function () {
      for (var i = 0; i < element_10_161.length; i++) {
        element_10_161[i].classList.remove('LenteInativa')
      }
      for (var i = 0; i < element_10_161.length; i++) {
        element_10_161[i].classList.add('LenteAtiva')
      }
    }, 200)
  }

  for (var i = 0; i < Btn_10_150.length; i++) {
    Btn_10_150[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_156.length; i++) {
    Btn_10_156[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_161.length; i++) {
    Btn_10_161[i].classList.add('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_167.length; i++) {
    Btn_10_167[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_174.length; i++) {
    Btn_10_174[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_Todas10.length; i++) {
    Btn_Todas10[i].classList.remove('BtnDefault_Ativo')
  }

  //NomeDaRefracao.html('Com índice de refração 1.61')
}

export function Btn_10_167() {
  var element_10_150 = document.getElementsByClassName('10_150')
  var element_10_156 = document.getElementsByClassName('10_156')
  var element_10_161 = document.getElementsByClassName('10_161')
  var element_10_167 = document.getElementsByClassName('10_167')
  var element_10_174 = document.getElementsByClassName('10_174')

  var Btn_10_150 = document.getElementsByClassName('Btn_10_150')
  var Btn_10_156 = document.getElementsByClassName('Btn_10_156')
  var Btn_10_161 = document.getElementsByClassName('Btn_10_161')
  var Btn_10_167 = document.getElementsByClassName('Btn_10_167')
  var Btn_10_174 = document.getElementsByClassName('Btn_10_174')
  var Btn_Todas10 = document.getElementsByClassName('Btn_Todas10')

  //var NomeDaRefracao = document.getElementsByClassName('NomeDaRefracao')
  document
    .getElementById('Background_Espessuras')
    .classList.remove('Afasta_Background')
  document.getElementById('Background_Sala').classList.remove('Afasta_Sala')
  for (var i = 0; i < element_10_167.length; i++) {
    element_10_167[i].classList.remove('Todas10_167')
  }

  for (var i = 0; i < element_10_167.length; i++) {
    for (var i = 0; i < element_10_150.length; i++) {
      element_10_150[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_156.length; i++) {
      element_10_156[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_161.length; i++) {
      element_10_161[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_174.length; i++) {
      element_10_174[i].classList.add('LenteInativa')
    }

    setTimeout(function () {
      for (var i = 0; i < element_10_167.length; i++) {
        element_10_167[i].classList.remove('LenteInativa')
      }
      for (var i = 0; i < element_10_167.length; i++) {
        element_10_167[i].classList.add('LenteAtiva')
      }
    }, 200)
  }

  for (var i = 0; i < Btn_10_150.length; i++) {
    Btn_10_150[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_156.length; i++) {
    Btn_10_156[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_161.length; i++) {
    Btn_10_161[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_167.length; i++) {
    Btn_10_167[i].classList.add('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_174.length; i++) {
    Btn_10_174[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_Todas10.length; i++) {
    Btn_Todas10[i].classList.remove('BtnDefault_Ativo')
  }
  //NomeDaRefracao.html('Com índice de refração 1.67')
}

export function Btn_10_174() {
  var element_10_150 = document.getElementsByClassName('10_150')
  var element_10_156 = document.getElementsByClassName('10_156')
  var element_10_161 = document.getElementsByClassName('10_161')
  var element_10_167 = document.getElementsByClassName('10_167')
  var element_10_174 = document.getElementsByClassName('10_174')

  var Btn_10_150 = document.getElementsByClassName('Btn_10_150')
  var Btn_10_156 = document.getElementsByClassName('Btn_10_156')
  var Btn_10_161 = document.getElementsByClassName('Btn_10_161')
  var Btn_10_167 = document.getElementsByClassName('Btn_10_167')
  var Btn_10_174 = document.getElementsByClassName('Btn_10_174')
  var Btn_Todas10 = document.getElementsByClassName('Btn_Todas10')

  //var NomeDaRefracao = document.getElementsByClassName('NomeDaRefracao')

  document
    .getElementById('Background_Espessuras')
    .classList.remove('Afasta_Background')
  document.getElementById('Background_Sala').classList.remove('Afasta_Sala')

  for (var i = 0; i < element_10_174.length; i++) {
    element_10_174[i].classList.remove('Todas10_174')
  }

  for (var i = 0; i < element_10_174.length; i++) {
    for (var i = 0; i < element_10_150.length; i++) {
      element_10_150[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_156.length; i++) {
      element_10_156[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_161.length; i++) {
      element_10_161[i].classList.add('LenteInativa')
    }
    for (var i = 0; i < element_10_167.length; i++) {
      element_10_167[i].classList.add('LenteInativa')
    }

    setTimeout(function () {
      for (var i = 0; i < element_10_174.length; i++) {
        element_10_174[i].classList.remove('LenteInativa')
      }
      for (var i = 0; i < element_10_174.length; i++) {
        element_10_174[i].classList.add('LenteAtiva')
      }
    }, 200)
  }

  for (var i = 0; i < Btn_10_150.length; i++) {
    Btn_10_150[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_156.length; i++) {
    Btn_10_156[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_161.length; i++) {
    Btn_10_161[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_167.length; i++) {
    Btn_10_167[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_174.length; i++) {
    Btn_10_174[i].classList.add('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_Todas10.length; i++) {
    Btn_Todas10[i].classList.remove('BtnDefault_Ativo')
  }

  //NomeDaRefracao.html('Com índice de refração 1.74')
}

export function Btn_Todas10() {
  var element_10_150 = document.getElementsByClassName('10_150')
  var element_10_156 = document.getElementsByClassName('10_156')
  var element_10_161 = document.getElementsByClassName('10_161')
  var element_10_167 = document.getElementsByClassName('10_167')
  var element_10_174 = document.getElementsByClassName('10_174')

  var Btn_10_150 = document.getElementsByClassName('Btn_10_150')
  var Btn_10_156 = document.getElementsByClassName('Btn_10_156')
  var Btn_10_161 = document.getElementsByClassName('Btn_10_161')
  var Btn_10_167 = document.getElementsByClassName('Btn_10_167')
  var Btn_10_174 = document.getElementsByClassName('Btn_10_174')
  var Btn_Todas10 = document.getElementsByClassName('Btn_Todas10')

  for (var i = 0; i < element_10_150.length; i++) {
    element_10_150[i].classList.add('LenteAtiva')
  }
  for (var i = 0; i < element_10_150.length; i++) {
    element_10_150[i].classList.remove('LenteInativa')
  }
  for (var i = 0; i < element_10_150.length; i++) {
    element_10_150[i].classList.add('Todas10_150')
  }
  //   $('.10_150_Mais').classList.add('Todas10_150_Mais')

  for (var i = 0; i < element_10_156.length; i++) {
    element_10_156[i].classList.add('LenteAtiva')
  }
  for (var i = 0; i < element_10_156.length; i++) {
    element_10_156[i].classList.remove('LenteInativa')
  }
  for (var i = 0; i < element_10_156.length; i++) {
    element_10_156[i].classList.add('Todas10_156')
  }

  for (var i = 0; i < element_10_161.length; i++) {
    element_10_161[i].classList.add('LenteAtiva')
  }
  for (var i = 0; i < element_10_161.length; i++) {
    element_10_161[i].classList.remove('LenteInativa')
  }
  for (var i = 0; i < element_10_161.length; i++) {
    element_10_161[i].classList.add('Todas10_161')
  }

  for (var i = 0; i < element_10_167.length; i++) {
    element_10_167[i].classList.add('LenteAtiva')
  }
  for (var i = 0; i < element_10_167.length; i++) {
    element_10_167[i].classList.remove('LenteInativa')
  }
  for (var i = 0; i < element_10_167.length; i++) {
    element_10_167[i].classList.add('Todas10_167')
  }

  for (var i = 0; i < element_10_174.length; i++) {
    element_10_174[i].classList.add('LenteAtiva')
  }
  for (var i = 0; i < element_10_174.length; i++) {
    element_10_174[i].classList.remove('LenteInativa')
  }
  for (var i = 0; i < element_10_174.length; i++) {
    element_10_174[i].classList.add('Todas10_174')
  }

  document
    .getElementById('Background_Espessuras')
    .classList.add('Afasta_Background')
  document.getElementById('Background_Sala').classList.add('Afasta_Sala')

  for (var i = 0; i < Btn_10_150.length; i++) {
    Btn_10_150[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_156.length; i++) {
    Btn_10_156[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_161.length; i++) {
    Btn_10_161[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_167.length; i++) {
    Btn_10_167[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_10_174.length; i++) {
    Btn_10_174[i].classList.remove('BtnDefault_Ativo')
  }
  for (var i = 0; i < Btn_Todas10.length; i++) {
    Btn_Todas10[i].classList.add('BtnDefault_Ativo')
  }

  //NomeDaRefracao.html('Comparação dos índices de refração.')
}

export function Abre2() {
  var DivLentes = document.getElementsByClassName('DivLentes')

  document.getElementById('heading_4').classList.add('BtnBall_Ativo')

  document.getElementById('heading_3').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_2').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_1').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_5').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_6').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_7').classList.remove('BtnBall_Ativo')

  for (var i = 0; i < DivLentes.length; i++) {
    DivLentes[i].classList.add('Esconde_DivGrau')
  }

  setTimeout(function () {
    document.getElementById('Grau2').classList.remove('Esconde_DivGrau')
    document.getElementById('Grau4').classList.add('Esconde_DivGrau')
    document.getElementById('Grau6').classList.add('Esconde_DivGrau')
    document.getElementById('Grau10').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais2').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais4').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais6').classList.add('Esconde_DivGrau')
    for (var i = 0; i < DivLentes.length; i++) {
      DivLentes[i].classList.remove('Esconde_DivGrau')
    }
  }, 500)
}

export function Abre4() {
  var DivLentes = document.getElementsByClassName('DivLentes')

  document.getElementById('heading_4').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_3').classList.add('BtnBall_Ativo')

  document.getElementById('heading_2').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_1').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_5').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_6').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_7').classList.remove('BtnBall_Ativo')

  for (var i = 0; i < DivLentes.length; i++) {
    DivLentes[i].classList.add('Esconde_DivGrau')
  }

  setTimeout(function () {
    document.getElementById('Grau2').classList.add('Esconde_DivGrau')
    document.getElementById('Grau4').classList.remove('Esconde_DivGrau')
    document.getElementById('Grau6').classList.add('Esconde_DivGrau')
    document.getElementById('Grau10').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais2').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais4').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais6').classList.add('Esconde_DivGrau')
    for (var i = 0; i < DivLentes.length; i++) {
      DivLentes[i].classList.remove('Esconde_DivGrau')
    }
  }, 500)
}

// It might be used to all of the other calls
export function abreEsferico(x){
  var DivLentes = document.getElementsByClassName('DivLentes')

  for (var i = 1; i <= 7; i++) {
    if(x===i){
      document.getElementById('collapse_'+i).classList.add('show')
      document.getElementById('heading_'+i).classList.add('BtnBall_Ativo')
    } 
    else{
      document.getElementById('collapse_'+i).classList.remove('BtnBall_Ativo','show')
      document.getElementById('heading_'+i).classList.remove('BtnBall_Ativo')
    }
  }
  for (var i = 0; i < DivLentes.length; i++) {
    DivLentes[i].classList.add('Esconde_DivGrau')
  }
  setTimeout(function () {
    for (var i = 1; i <= 7; i++) {
      if(x===i){
        document.getElementById('grau_'+i).classList.remove('Esconde_DivGrau')
      }
      else{
        document.getElementById('grau_'+i).classList.add('Esconde_DivGrau')
      }
    }
    for (var i = 0; i < DivLentes.length; i++) {
      DivLentes[i].classList.remove('Esconde_DivGrau')
    }
  }, 500)

}

export function Abre6() {
  var DivLentes = document.getElementsByClassName('DivLentes')
  document.getElementById('collapse_1').classList.remove('show')
  document.getElementById('collapse_2').classList.add('show')
  document.getElementById('collapse_3').classList.remove('BtnBall_Ativo')
  document.getElementById('collapse_4').classList.remove('BtnBall_Ativo')
  document.getElementById('collapse_5').classList.remove('BtnBall_Ativo')
  document.getElementById('collapse_6').classList.remove('BtnBall_Ativo')
  document.getElementById('collapse_7').classList.remove('BtnBall_Ativo')

  for (var i = 0; i < DivLentes.length; i++) {
    DivLentes[i].classList.add('Esconde_DivGrau')
  }

  setTimeout(function () {
    document.getElementById('Grau2').classList.add('Esconde_DivGrau')
    document.getElementById('Grau4').classList.add('Esconde_DivGrau')
    document.getElementById('Grau6').classList.remove('Esconde_DivGrau')
    document.getElementById('Grau10').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais2').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais4').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais6').classList.add('Esconde_DivGrau')
    for (var i = 0; i < DivLentes.length; i++) {
      DivLentes[i].classList.remove('Esconde_DivGrau')
    }
  }, 500)
}

export function Abre10() {
  var DivLentes = document.getElementsByClassName('DivLentes')
  document.getElementById('heading_4').classList.remove('BtnBall_Ativo')
  document.getElementById('heading_3').classList.remove('BtnBall_Ativo')
  document.getElementById('heading_2').classList.remove('BtnBall_Ativo')
  document.getElementById('heading_1').classList.add('BtnBall_Ativo')
  document.getElementById('heading_5').classList.remove('BtnBall_Ativo')
  document.getElementById('heading_6').classList.remove('BtnBall_Ativo')
  document.getElementById('heading_7').classList.remove('BtnBall_Ativo')

  for (var i = 0; i < DivLentes.length; i++) {
    DivLentes[i].classList.add('Esconde_DivGrau')
  }

  setTimeout(function () {
    document.getElementById('Grau2').classList.add('Esconde_DivGrau')
    document.getElementById('Grau4').classList.add('Esconde_DivGrau')
    document.getElementById('Grau6').classList.add('Esconde_DivGrau')
    document.getElementById('Grau10').classList.remove('Esconde_DivGrau')
    document.getElementById('GrauMais2').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais4').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais6').classList.add('Esconde_DivGrau')
    for (var i = 0; i < DivLentes.length; i++) {
      DivLentes[i].classList.remove('Esconde_DivGrau')
    }
  }, 500)
}

export function AbreMais2() {
  var DivLentes = document.getElementsByClassName('DivLentes')

  document.getElementById('heading_4').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_3').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_2').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_1').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_5').classList.add('BtnBall_Ativo')

  document.getElementById('heading_6').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_7').classList.remove('BtnBall_Ativo')

  for (var i = 0; i < DivLentes.length; i++) {
    DivLentes[i].classList.add('Esconde_DivGrau')
  }

  setTimeout(function () {
    document.getElementById('Grau2').classList.add('Esconde_DivGrau')
    document.getElementById('Grau4').classList.add('Esconde_DivGrau')
    document.getElementById('Grau6').classList.add('Esconde_DivGrau')
    document.getElementById('Grau10').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais2').classList.remove('Esconde_DivGrau')
    document.getElementById('GrauMais4').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais6').classList.add('Esconde_DivGrau')
    for (var i = 0; i < DivLentes.length; i++) {
      DivLentes[i].classList.remove('Esconde_DivGrau')
    }
  }, 500)
}

export function AbreMais4() {
  var DivLentes = document.getElementsByClassName('DivLentes')

  document.getElementById('heading_4').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_3').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_2').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_1').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_5').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_6').classList.add('BtnBall_Ativo')

  document.getElementById('heading_7').classList.remove('BtnBall_Ativo')

  for (var i = 0; i < DivLentes.length; i++) {
    DivLentes[i].classList.add('Esconde_DivGrau')
  }

  setTimeout(function () {
    document.getElementById('Grau2').classList.add('Esconde_DivGrau')
    document.getElementById('Grau4').classList.add('Esconde_DivGrau')
    document.getElementById('Grau6').classList.add('Esconde_DivGrau')
    document.getElementById('Grau10').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais2').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais4').classList.remove('Esconde_DivGrau')
    document.getElementById('GrauMais6').classList.add('Esconde_DivGrau')
    for (var i = 0; i < DivLentes.length; i++) {
      DivLentes[i].classList.remove('Esconde_DivGrau')
    }
  }, 500)
}

export function AbreMais6() {
  // var element_10_156 = document.getElementsByClassName('10_156')
  // var Btn_10_156 = document.getElementsByClassName('Btn_10_156')

  var DivLentes = document.getElementsByClassName('DivLentes')

  document.getElementById('heading_4').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_3').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_2').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_1').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_5').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_6').classList.remove('BtnBall_Ativo')

  document.getElementById('heading_7').classList.add('BtnBall_Ativo')

  for (var i = 0; i < DivLentes.length; i++) {
    DivLentes[i].classList.add('Esconde_DivGrau')
  }

  setTimeout(function () {
    document.getElementById('Grau2').classList.add('Esconde_DivGrau')
    document.getElementById('Grau4').classList.add('Esconde_DivGrau')
    document.getElementById('Grau6').classList.add('Esconde_DivGrau')
    document.getElementById('Grau10').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais2').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais4').classList.add('Esconde_DivGrau')
    document.getElementById('GrauMais6').classList.remove('Esconde_DivGrau')
    for (var i = 0; i < DivLentes.length; i++) {
      DivLentes[i].classList.remove('Esconde_DivGrau')
    }
  }, 500)
}
