<template>
  <div>
    <Header />

    <!--Beneficios-->
    <!--Beneficios-->
    <div class="DivFull_LeftCenter DivPortaBtnVoltaBeneficios">
      <div class="DivBtnVoltaBeneficios">
        <a
          href="javascript:void(0)"
          class="BtnVoltaBeneficios"
          id="BtnVoltaBeneficios"
        >
          <img src="./svg/ShowBeneficios.svg" class="" alt="CLOSE" />
        </a>
      </div>
    </div>

    <!--Beneficios-->
    <div class="DivFull_LeftCenter Beneficios" id="Beneficios">
      <div class="container-fluid ContainterFluidMenor_Espessuras">
        <div class="col-md-6">
          <h1 class="TituloCor1">
            Espessuras<br />
            das Lentes
          </h1>

          <h2 class="Header_Azul Hide" id="TituloVisaoSimples"></h2>

          <div class="row CollapseEspessuras">
            <div class="col-md-auto">
              <div class="row TextoGraus top20">
                <h3 class="TituloCor3">Esférico Negativo</h3>
              </div>

              <div class="row MenuGraus top10">
                <a
                  @click="abreEsferico(1)"
                  class="BtnBall BtnBall_Ativo Abre10"
                  id="heading_1"
                  data-toggle="collapse"
                  data-target="#collapse_1"
                  aria-expanded="true"
                  aria-controls="collapse_1"
                  >-10.00</a
                >

                <a
                  @click="abreEsferico(2)"
                  class="BtnBall Abre6"
                  id="heading_2"
                  data-toggle="collapse"
                  data-target="#collapse_2"
                  aria-expanded="true"
                  aria-controls="collapse_2"
                  >-6.00</a
                >

                <a
                  @click="abreEsferico(3)"
                  class="BtnBall Abre4"
                  id="heading_3"
                  data-toggle="collapse"
                  data-target="#collapse_3"
                  aria-expanded="true"
                  aria-controls="collapse_3"
                  >-4.00</a
                >

                <a
                  @click="abreEsferico(4)"
                  class="BtnBall Abre2"
                  id="heading_4"
                  data-toggle="collapse"
                  data-target="#collapse_4"
                  aria-expanded="true"
                  aria-controls="collapse_4"
                  >-2.00</a
                >
              </div>
            </div>

            <div class="col-md-auto">
              <div class="row TextoGraus top20">
                <h3 class="TituloCor3">Esférico Positivo</h3>
              </div>

              <div class="row MenuGraus top10">
                <a
                  @click="abreEsferico(5)"
                  class="BtnBall AbreMais2"
                  id="heading_5"
                  data-toggle="collapse"
                  data-target="#collapse_5"
                  aria-expanded="true"
                  aria-controls="collapse_5"
                  >+2.00</a
                >

                <a
                  @click="abreEsferico(6)"
                  class="BtnBall AbreMais4"
                  id="heading_6"
                  data-toggle="collapse"
                  data-target="#collapse_6"
                  aria-expanded="true"
                  aria-controls="collapse_6"
                  >+4.00</a
                >

                <a
                  @click="abreEsferico(7)"
                  class="BtnBall AbreMais6"
                  id="heading_7"
                  data-toggle="collapse"
                  data-target="#collapse_7"
                  aria-expanded="true"
                  aria-controls="collapse_7"
                  >+6.00</a
                >
              </div>
            </div>
          </div>

          <!--Collapse Espessuras-->
          <div class="accordion CollapseEspessuras" id="accordionExample">
            <!--Lentes 1-->
            <div class="card" style="margin-bottom: 0 !important;">
              <div
                id="collapse_1"
                class="collapse show"
                aria-labelledby="heading_1"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <h3 class="TituloCor3">Grau Esférico -10,00</h3>

                  <h3 class="TituloCor3 NomeDaRefracao" id="">
                    Com índice de refração 1.50
                  </h3>

                  <p class="Txt_EmLinha_Espessuras top10 Azul1 bottom10">
                    Outros índices de refração:
                  </p>

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm BtnDefault_Ativo Btn_10_150"
                    @click="Btn_10_150()"
                    >1.50</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_156"
                    @click="Btn_10_156()"
                    >1.56</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_161"
                    @click="Btn_10_161()"
                    >1.61</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_167"
                    @click="Btn_10_167()"
                    >1.67</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_174"
                    @click="Btn_10_174()"
                    >1.74</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_Todas10"
                    @click="Btn_Todas10()"
                    >COMPARAR</a
                  >
                </div>
              </div>
            </div>
            <!--Lentes 1-->

            <!--Lentes 2-->
            <div class="card" style="margin-bottom: 0 !important;">
              <div
                id="collapse_2"
                class="collapse"
                aria-labelledby="heading_2"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <h3 class="TituloCor3">Grau Esférico -6,00</h3>

                  <h3 class="TituloCor3 NomeDaRefracao" id="">
                    Com índice de refração 1.50
                  </h3>

                  <p class="Txt_EmLinha_Espessuras top10 Azul1 bottom10">
                    Outros índices de refração:
                  </p>

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm BtnDefault_Ativo Btn_10_150"
                    @click="Btn_10_150()"
                    >1.50</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_156"
                    @click="Btn_10_156()"
                    >1.56</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_161"
                    @click="Btn_10_161()"
                    >1.61</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_167"
                    @click="Btn_10_167()"
                    >1.67</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_174"
                    @click="Btn_10_174()"
                    >1.74</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_Todas10"
                    @click="Btn_Todas10()"
                    >COMPARAR</a
                  >
                </div>
              </div>
            </div>
            <!--Lentes 2-->

            <!--Lentes 3-->
            <div class="card" style="margin-bottom: 0 !important;">
              <div
                id="collapse_3"
                class="collapse"
                aria-labelledby="heading_3"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <h3 class="TituloCor3">Grau Esférico -4,00</h3>

                  <h3 class="TituloCor3 NomeDaRefracao" id="">
                    Com índice de refração 1.50
                  </h3>

                  <p class="Txt_EmLinha_Espessuras top10 Azul1 bottom10">
                    Outros índices de refração:
                  </p>

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm BtnDefault_Ativo Btn_10_150"
                    @click="Btn_10_150()"
                    >1.50</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_156"
                    @click="Btn_10_156()"
                    >1.56</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_161"
                    @click="Btn_10_161()"
                    >1.61</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_167"
                    @click="Btn_10_167()"
                    >1.67</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_174"
                    @click="Btn_10_174()"
                    >1.74</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_Todas10"
                    @click="Btn_Todas10()"
                    >COMPARAR</a
                  >
                </div>
              </div>
            </div>
            <!--Lentes 3-->

            <!--Lentes 4-->
            <div class="card" style="margin-bottom: 0 !important;">
              <div
                id="collapse_4"
                class="collapse"
                aria-labelledby="heading_4"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <h3 class="TituloCor3">Grau Esférico -2,00</h3>

                  <h3 class="TituloCor3 NomeDaRefracao" id="">
                    Com índice de refração 1.50
                  </h3>

                  <p class="Txt_EmLinha_Espessuras top10 Azul1 bottom10">
                    Outros índices de refração:
                  </p>

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm BtnDefault_Ativo Btn_10_150"
                    @click="Btn_10_150()"
                    >1.50</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_156"
                    @click="Btn_10_156()"
                    >1.56</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_161"
                    @click="Btn_10_161()"
                    >1.61</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_167"
                    @click="Btn_10_167()"
                    >1.67</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_174"
                    @click="Btn_10_174()"
                    >1.74</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_Todas10"
                    @click="Btn_Todas10()"
                    >COMPARAR</a
                  >
                </div>
              </div>
            </div>
            <!--Lentes 4-->

            <!--Lentes 5-->
            <div class="card" style="margin-bottom: 0 !important;">
              <div
                id="collapse_5"
                class="collapse"
                aria-labelledby="heading_5"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <h3 class="TituloCor3">Grau Esférico +2,00</h3>

                  <h3 class="TituloCor3 NomeDaRefracao" id="">
                    Com índice de refração 1.50
                  </h3>

                  <p class="Txt_EmLinha_Espessuras top10 Azul1 bottom10">
                    Outros índices de refração:
                  </p>

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm BtnDefault_Ativo Btn_10_150"
                    @click="Btn_10_150()"
                    >1.50</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_161"
                    @click="Btn_10_161()"
                    >1.61</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_167"
                    @click="Btn_10_167()"
                    >1.67</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_174"
                    @click="Btn_10_174()"
                    >1.74</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_Todas10"
                    @click="Btn_Todas10()"
                    >COMPARAR</a
                  >
                </div>
              </div>
            </div>
            <!--Lentes 5-->

            <!--Lentes 6-->
            <div class="card" style="margin-bottom: 0 !important;">
              <div
                id="collapse_6"
                class="collapse"
                aria-labelledby="heading_6"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <h3 class="TituloCor3">Grau Esférico +4,00</h3>

                  <h3 class="TituloCor3 NomeDaRefracao" id="">
                    Com índice de refração 1.50
                  </h3>

                  <p class="Txt_EmLinha_Espessuras top10 Azul1 bottom10">
                    Outros índices de refração:
                  </p>

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm BtnDefault_Ativo Btn_10_150"
                    @click="Btn_10_150()"
                    >1.50</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_161"
                    @click="Btn_10_161()"
                    >1.61</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_167"
                    @click="Btn_10_167()"
                    >1.67</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_174"
                    @click="Btn_10_174()"
                    >1.74</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_Todas10"
                    @click="Btn_Todas10()"
                    >COMPARAR</a
                  >
                </div>
              </div>
            </div>
            <!--Lentes 6-->

            <!--Lentes 7-->
            <div class="card" style="margin-bottom: 0 !important;">
              <div
                id="collapse_7"
                class="collapse"
                aria-labelledby="heading_7"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <h3 class="TituloCor3">Grau Esférico +6,00</h3>

                  <h3 class="TituloCor3 NomeDaRefracao" id="">
                    Com índice de refração 1.50
                  </h3>

                  <p class="Txt_EmLinha_Espessuras top10 Azul1 bottom10">
                    Outros índices de refração:
                  </p>

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm BtnDefault_Ativo Btn_10_150"
                    @click="Btn_10_150()"
                    >1.50</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_161"
                    @click="Btn_10_161()"
                    >1.61</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_167"
                    @click="Btn_10_167()"
                    >1.67</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_10_174"
                    @click="Btn_10_174()"
                    >1.74</a
                  >

                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm Btn_Todas10"
                    @click="Btn_Todas10()"
                    >COMPARAR</a
                  >
                </div>
              </div>
            </div>
            <!--Lentes 7-->
          </div>
          <!--Collapse Espessuras-->
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <div
      data-depth="0.20"
      class="DivFull_CenterCenter Espessuras"
      id="Background_Espessuras"
    ></div>

    <div
      class="DivFull_CenterCenter BackgroundEspessuras"
      id="Background_Sala"
    ></div>

    <div class="DivFull_CenterCenter Div_TodasEspessuras">
      <div id="grau_1" class="Grau10">
        <div class="DivLentes Div_10_150 LenteAtiva 10_150" id=""></div>

        <div class="DivLentes Div_10_156 LenteInativa 10_156" id=""></div>

        <div class="DivLentes Div_10_161 LenteInativa 10_161" id=""></div>

        <div class="DivLentes Div_10_167 LenteInativa 10_167" id=""></div>

        <div class="DivLentes Div_10_174 LenteInativa 10_174" id=""></div>
      </div>

      <div id="grau_2" class="Grau6 Esconde_DivGrau">
        <div class="DivLentes Div_6_150 LenteAtiva 10_150" id=""></div>

        <div class="DivLentes Div_6_156 LenteInativa 10_156" id=""></div>

        <div class="DivLentes Div_6_161 LenteInativa 10_161" id=""></div>

        <div class="DivLentes Div_6_167 LenteInativa 10_167" id=""></div>

        <div class="DivLentes Div_6_174 LenteInativa 10_174" id=""></div>
      </div>

      <div id="grau_3" class="Grau4 Esconde_DivGrau">
        <div class="DivLentes Div_4_150 LenteAtiva 10_150" id=""></div>

        <div class="DivLentes Div_4_156 LenteInativa 10_156" id=""></div>

        <div class="DivLentes Div_4_161 LenteInativa 10_161" id=""></div>

        <div class="DivLentes Div_4_167 LenteInativa 10_167" id=""></div>

        <div class="DivLentes Div_4_174 LenteInativa 10_174" id=""></div>
      </div>

      <div id="grau_4" class="Grau2 Esconde_DivGrau">
        <div class="DivLentes Div_2_150 LenteAtiva 10_150" id=""></div>

        <div class="DivLentes Div_2_156 LenteInativa 10_156" id=""></div>

        <div class="DivLentes Div_2_161 LenteInativa 10_161" id=""></div>

        <div class="DivLentes Div_2_167 LenteInativa 10_167" id=""></div>

        <div class="DivLentes Div_2_174 LenteInativa 10_174" id=""></div>
      </div>

      <div id="grau_5" class="GrauMais2 Esconde_DivGrau">
        <div
          class="DivLentes Div_Mais2_150 LenteAtiva 10_150 10_150_Mais"
          id=""
        ></div>

        <div class="DivLentes Div_Mais2_161 LenteInativa 10_161" id=""></div>

        <div class="DivLentes Div_Mais2_167 LenteInativa 10_167" id=""></div>

        <div class="DivLentes Div_Mais2_174 LenteInativa 10_174" id=""></div>
      </div>

      <div id="grau_6" class="GrauMais4 Esconde_DivGrau">
        <div
          class="DivLentes Div_Mais4_150 LenteAtiva 10_150 10_150_Mais"
          id=""
        ></div>

        <div class="DivLentes Div_Mais4_161 LenteInativa 10_161" id=""></div>

        <div class="DivLentes Div_Mais4_167 LenteInativa 10_167" id=""></div>

        <div class="DivLentes Div_Mais4_174 LenteInativa 10_174" id=""></div>
      </div>

      <div id="grau_7" class="GrauMais6 Esconde_DivGrau">
        <div
          class="DivLentes Div_Mais6_150 LenteAtiva 10_150 10_150_Mais"
          id=""
        ></div>

        <div class="DivLentes Div_Mais6_161 LenteInativa 10_161" id=""></div>

        <div class="DivLentes Div_Mais6_167 LenteInativa 10_167" id=""></div>

        <div class="DivLentes Div_Mais6_174 LenteInativa 10_174" id=""></div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/views/menu/components/header.vue'

import {
  Btn_10_150,
  Btn_10_156,
  Btn_10_161,
  Btn_10_167,
  Btn_10_174,
  Btn_Todas10,
  Abre2,
  Abre4,
  Abre6,
  Abre10,
  AbreMais2,
  AbreMais4,
  AbreMais6,
  abreEsferico,
} from '@/views/menu/js/new/Thickness_Script.js'

export default {
  components: {
    Header,
  },
  methods: {
    Btn_10_150,
    Btn_10_156,
    Btn_10_161,
    Btn_10_167,
    Btn_10_174,
    Btn_Todas10,
    Abre2,
    Abre4,
    Abre6,
    Abre10,
    AbreMais2,
    AbreMais4,
    AbreMais6,
    abreEsferico,
  },
}
</script>
<style scoped>
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import './css/plugins/owl.carousel.min.css'; */
@import './css/plugins/owl.theme.default.min.css';
@import './css/Espessuras_Style.css';
</style>
